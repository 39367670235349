import React, { Component } from "react";
import { Image, Grid, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import "./Papers.css";
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const pStyle = {
  marginLeft: '20px',
  marginRight: '20px',
};



export default class Home extends Component {
  render() {
    return (
      <div className="Papers">


        {/* https://en.wikipedia.org/wiki/File:Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg */}
        {/*
        <Image src="2048px-Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg" fluid />
        */}

{/*
        <div className="lander">
          <h1>Scratch</h1>
          <p>A simple note taking app</p>
        </div>
*/}



        <div className="Research Papers">
          <div>

          <center>
            <h1>
            {/* user-edit */}
            <FontAwesomeIcon icon="user-edit" size="2x" />
            &nbsp; 
            Research Papers
            </h1>
{/*
              <p>Job Experience I've had</p>
<a href="Hanke--all_papers__as_of_2015-01-14/">
*/}
          </center>


                  <h3> Number Theory </h3>
{/*
                  My research explores theoretical and computational questions and answers about the arithmetic of quadratic forms.
*/}
                  My research in number theory uses theoretical and computational methods to answer classical questions about the Arithmetic of Quadratic Forms, 
                  and related questions about Modular and Automorphic forms.  

                  <p style={pStyle}>
                    <h4> Expository Papers </h4> 
                    <ul>  
                      <li> <a href="Hanke--all_papers__as_of_2015-01-14/AWS-quadratic-automorphic-notes.pdf">Notes on “Quadratic Forms and Automorphic Forms” </a> 
                            <br /> &nbsp; &nbsp; &nbsp;
                            (73 pages; Revised from the <a href="http://swc.math.arizona.edu/aws/2009/index.html">2009 Arizona Winter School</a>) </li>
                      <li> <a href="Hanke--all_papers__as_of_2015-01-14/ternary-qfs-survey.pdf">Some Recent Results about (Ternary) Quadratic Forms</a> 
                            <br /> &nbsp; &nbsp; &nbsp;
                            (19 pages)</li>
                    </ul>


                    <h4> Numbers Represented by Quadratic Forms </h4> 
                    <ul>  
                      <li> <a href="Hanke--all_papers__as_of_2015-01-14/290-theorem-preprint.pdf">Universal Quadratic Forms and the 290-Theorem</a>
                            <br /> &nbsp; &nbsp; &nbsp;
                            (with M. Bhargava; 16 pages)</li>
                      <li> <a href="Hanke--all_papers__as_of_2015-01-14/explicit-bounds-paper.pdf">Local densities and explicit bounds for representability by a quadratic form</a> 
                            <br /> &nbsp; &nbsp; &nbsp;
                            (38 pages)</li>
{/*
                            <br /> &nbsp; &nbsp; &nbsp;
                            (37 pages)
                            <br /> &nbsp; &nbsp; &nbsp;
                            (Duke Math. J. 124 (2004), no. 2, 351–388; 37 pages)
*/}
{/*
<a href="local-densities-explicit-bounds--DMJ.pdf"> Clickable DMJ-version </a>
*/}

{/*
                      <li> <a href="Hanke--all_papers__as_of_2015-01-14/PREPRINT__On_a_local-global_principle_for_ternary_quadratic_forms.pdf"> On a local-global principle for ternary quadratic forms (Unpublished preprint)</a>
                      </li>
*/}
                    </ul>


                    <h4> Quadratic Form Mass Formulas (for a genus) </h4>
                    <ul> 
                      <li> <a href="Hanke--all_papers__as_of_2015-01-14/exact-mass-shimura-paper.pdf">On an Exact Mass Formula of Shimura</a>
                            <br /> &nbsp; &nbsp; &nbsp;
                            (with W.T. Gan and J.K. Yu; 31 pages)</li>
                      <li> <a href="Hanke--all_papers__as_of_2015-01-14/phd-thesis-paper.pdf">An Exact Mass Formula for Quadratic Forms over Number Fields</a> 
                            <br /> &nbsp; &nbsp; &nbsp;
                            (25 pages; Ph.D. Thesis as paper)</li>
                    </ul>


                    <h4> Quadratic Form Mass Formulas (for a determinant) </h4>
                    <ul> 
                      <li> <a href="Hanke--all_papers__as_of_2015-01-14/explicit-ternary-masses-by-det.pdf">Explicit formulas for Masses of Ternary Quadratic Lattices of varying determinant over Number Fields</a> 
                            <br /> &nbsp; &nbsp; &nbsp;
                            (28 pages)</li>
                      <li> <a href="Hanke--all_papers__as_of_2015-01-14/structure-of-masses-by-det.pdf">The Structure of Masses of rank <InlineMath>n</InlineMath> Quadratic Lattices of varying determinant over Number Fields</a> 
                            <br /> &nbsp; &nbsp; &nbsp;
                            (39 pages)</li>
                    </ul>


                    <h4> Enumerating Quadratic Forms of Bounded Class Number </h4>
                    <ul> 
                      <li> <a href="Hanke--all_papers__as_of_2015-01-14/enumerating-maximal-definite-qfs.pdf">Enumerating maximal definite quadratic forms of bounded class number 
                            over <InlineMath>{"\\mathbb{Z}"}</InlineMath> in <InlineMath>n \geq 3 </InlineMath> variables</a> 
                            <br /> &nbsp; &nbsp; &nbsp;
                            (21 pages)</li>
                      <li> <a href="Hanke--all_papers__as_of_2015-01-14/algorithms-for-maximal-lattices.pdf">Algorithms for computing maximal lattices in bilinear (and quadratic) spaces over number fields</a> 
                            <br /> &nbsp; &nbsp; &nbsp;
                            (24 pages)</li>
                    </ul>


                    <h4> Arithmetic Statistics </h4>
                    <ul> 
                      <li> <a href="Hanke--2020_papers/2020-10_29__n-monogenized_paper.pdf"> The mean number of 2-torsion elements in the class groups of <InlineMath>{"n"}</InlineMath>-monogenized cubic fields </a> 
                            <br /> &nbsp; &nbsp; &nbsp;
                            (with M. Bhargava and A. Shankar; 44 pages)</li>
                    </ul>


                    <h4> Computing Cohomology and K-theory </h4>
                    <ul> 
                      <li> <a href="Hanke--all_papers__as_of_2015-01-14/K4-of-gaussian-and-eisenstein.pdf">On  <InlineMath>K_4 </InlineMath> of the Gaussian and Eisenstein Integers</a> 
                            <br /> &nbsp; &nbsp; &nbsp;
                            (with H. Gangl, P.E. Gunnells, A. Schurmann, M.D. Sikiric, and D. Yasaki; 10 pages) </li>
                      <li> <a href="Hanke--all_papers__as_of_2015-01-14/cohomology-over-imaginary-quadratic.pdf">On the cohomology of linear groups over imaginary quadratic fields</a> 
                            <br /> &nbsp; &nbsp; &nbsp;
                            (with H. Gangl, P.E. Gunnells, A. Schurmann, M.D. Sikiric, and D. Yasaki; 22 pages) </li>
                    </ul>

                    <h4> Genus Relations </h4>
                    <ul> 
                      <li> <a href="Hanke--all_papers__as_of_2015-01-14/S-genus-identities.pdf">A proof of the  <InlineMath>S </InlineMath>-genus Identities for Ternary Quadratic Forms</a>
                            <br /> &nbsp; &nbsp; &nbsp;
                            (with A. Berkovich and W.C. Jagy; 14 pages) </li>
                    </ul>

                    <h4> Elliptic Curves </h4>
                    <ul> 
                      <li> <a href="Hanke--all_papers__as_of_2015-01-14/undergrad-thesis.pdf"> Torsion on certain elliptic curves over <InlineMath>{"\\mathbb{Q}(\\sqrt{d})"}</InlineMath></a> 
                            <br /> &nbsp; &nbsp; &nbsp;
                            (34 pages; Undergraduate Thesis)</li>
                    </ul>

                  </p>


                  <p> &nbsp; </p>
                  <h3> Financial mathematics </h3>

                  My research in financial mathematics focuses on understanding volatility harvesting through Probabilistic and Stochastic formalisms for Portfolio Rebalancing.

                  <h4> Probabilistic and Stochastic Portfolio Theory </h4>
                  <p style={pStyle}>
                    <ul>  
                      <li> <a href="Hanke--all_papers__as_of_2015-01-14/constant-weighted-preprint.pdf">Probabilistic and Stochastic perspectives on instantaneously rebalanced constant-weighted portfolio strategies in an antisymmetric two stock market</a> </li>
                      <li> Notes on “Stochastic Portfolio Theory” (in progress) </li>
                      <li> <Link to="https://en.wikipedia.org/wiki/Stochastic_portfolio_theory"> Stochasic Portfolio Theory</Link> Wikipedia page 
                      (with E. R. Fernholz and  I. Karatzas)</li>
                    </ul>
                  </p>


{/*
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
*/}
          </div>

        </div>
  
      </div>

    );
  }
}