import React, { Component } from "react";
import { Image, Grid, Row, Col } from "react-bootstrap";
import "./Home.css";
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default class Home extends Component {
  render() {
    return (
      <div className="Home">


        {/* https://en.wikipedia.org/wiki/File:Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg */}
        {/*
        <Image src="2048px-Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg" fluid />
        */}

{/*
        <div className="lander">
          <h1>Scratch</h1>
          <p>A simple note taking app</p>
        </div>
*/}


        <div className="Research Overview">
          <center>
            <h1>
            <FontAwesomeIcon icon="binoculars" size="2x" />
            &nbsp; 
            Research Overview</h1>
          </center>

          <p> 
          <b> <h3>Number Theory</h3>  </b>
          My research in number theory focuses on the connection between the arithmetic of quadratic forms 
          and automorphic forms.  A prototypical result along these lines is Jacobi’s formula for the exact 
          number of integer solutions <InlineMath>(x, y, z, w)</InlineMath> of the equation
          
          <BlockMath>
          x^2 + y^2 + z^2 + w^2 = m
          </BlockMath>

          for any integer <InlineMath>m \geq 1</InlineMath>.  He was able to prove the exact formula that 
          the number of solutions is given by

          <BlockMath>
          {"r_4(m) =  8 \\sum_{{0 < d \\mid m, \\ 4 \\nmid d}} d"} 
          {/*
          8 \sum_{0 < d \mid m, 4 \nmid d} d
          */}
          </BlockMath>

          which is 8 times the sum of the positive divisors <InlineMath>d</InlineMath> of <InlineMath>m</InlineMath>,  where
          the number 4 doesn’t divide <InlineMath>d</InlineMath>.  This formula is not easy to prove, and Jacobi 
          used the rather surprising method of elliptic functions (which at first glance belongs much more to complex 
            analysis than to number theory) to derive this result.  Elliptic functions, and their close cousins called 
          “theta functions”, are objects that unify analysis and arithmetic as functions with a large number of 
          arithmetically interesting symmetries.

          The study of similar kinds of functions over the last 100 years has revealed the incredibly important nature 
          of such functions (i.e. automorphic forms) for using analytic methods and ideas to study arithmetic problems.

          My research program is to use similar ideas to study questions about the arithmetic of arbitrary quadratic 
          forms over the integers and rational numbers.  Below are several topics that I find interesting which motivate 
          my research program:
          <ul>
            <li> Mass formulas and Class numbers of Quadratic Forms </li>
            <li> Comparing Theta functions with Hecke eigenforms </li>
            <li> Local-global principles for quadratic forms (in small numbers of variables) </li>
            <li> Explicit representation and finiteness theorems for quadratic forms </li>
            <li> Uniqueness results and linear relations between theta series for quadratic forms </li>
            <li> Arithmetic parametrizations of class groups and related objects by quadratic forms </li>
            <li> “Theta functions” for other arithmetic embedding problems related to automorphic forms </li>
          </ul>
          </p>


          <b> <h3>Probabilistic / Stochastic Portfolio Theory</h3> </b> 
          My research in Stochastic Portfolio Theory is based on the ideas of the <a href="https://www.amazon.com/Stochastic-Portfolio-Modelling-Applied-Probability/dp/0387954058">"Stochastic Portfolio Theory"</a> 
          book by E. Robert Fernholz and research papers of <a href="http://www.math.columbia.edu/~ik/">Ioannis Karatzas</a>.  I have been interested to understand 
          the effect of volatity harvesting in probabilistic/stochastic markets whose underlying stochastic process is not based on 
          a Gaussian/It&ocirc; process, but instead based on a stable distribtions or other probabilistic distributions 
          whose fatter tails more accurately model the log-price movements of stocks in equity markets.

        </div>




        <h3> &nbsp;</h3>  
        <div className="Research Topics">
          <div>

          <center>
            <h1>
            <FontAwesomeIcon icon="street-view" size="2x" />
            &nbsp; 
            Research Topics</h1>
{/*
              <p>Job Experience I've had</p>
*/}
          </center>


            <Grid>
              <Row>
                <Col md={6} mdPush={6}>
                  <h3> Probabilistic / Stochastic Portfolio Theory </h3>
                  <p>
                    <ul>
                      <li> Setup a probabilistic framework for understanding 
                            voltility harvesting for  probabilistically defined markets</li>
                      <li> Co-authored the <a href="https://en.wikipedia.org/wiki/Stochastic_portfolio_theory">Wikipedia page on Stochastic Portfolio Theory</a> with leading researchers in the area to make the topic more widely accessible </li>
                    </ul>
                  </p>
                </Col>
                <Col md={6} mdPull={6}>
                  <h3> Number Theory </h3>
                  <p>
                    <ul>
                      <li> Algorithmically determining all numbers represented 
                            by any given positive-definite integer-valued 
                            Quadratic Form in <InlineMath>n \geq 4</InlineMath> variables</li>
                      <li> Explicit Finiteness Theorems and the 290-Theorem </li>
                      <li> Total Mass of Quadratic forms</li>
                      <li> Exact Mass Formulas via Tamagawa numbers </li>
                      <li> Pairs/Pencils of Ternary Quadratic Forms </li>
                      <li> Quadratic Form Computational Software </li>
                      <li> Enumerating Quadratic Forms with bounded class number </li>
                      <li> Quadratic Form Computational Software </li>
                      <li> Genus Relations </li>
                      <li> Computing Cohomology and <InlineMath>K</InlineMath>-theory </li>
                    </ul>
                  </p>
                </Col>
              </Row>
              
            </Grid>


{/*
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
*/}
          </div>

        </div>
  
      </div>

    );
  }
}