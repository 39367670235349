import React, { Component } from "react";
import { Image, Grid, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import "./Home.css";


function blueBorder(e) {
    e.target.style.backgroundColor = '#DDDDFF';
  }


function plainBorder(e) {
    e.target.style.backgroundColor = '#FFFFFF';
  }



export default class Home extends Component {
  render() {
    return (
      <div className="Home">


        {/* https://en.wikipedia.org/wiki/File:Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg */}
        {/*
        <Image src="2048px-Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg" fluid />
        */}

{/*
        <div className="lander">
          <h1>Scratch</h1>
          <p>A simple note taking app</p>
        </div>
*/}


{/*
        <div className="About me">
          <center>
            <h1>About Me</h1>
          </center>

          <p>
          I am a mathematician interested in Number Theory and Financial Mathematics 
          from both theoretical and computational perspectives.  My research focus has 
          been the study of quadratic forms over the integers, and their symmetries.  
          I have taught mathematics at Rutgers, Princeton, Duke, and the University of Georgia, 
          and have held visiting research positions at several mathematics research institutes 
          (MSRI, Max Planck Institute in Bonn).  I am also regularly involved with the PROMYS 
          summer program for high school students based at Boston University, 
          and was a founding member of the Board of Trustees for the PROMYS Foundation, and 
          serve on the Board of the Association of Princeton Graduate Alumni.
          </p>
        </div>
*/}



{/*
        <div className="lander">
          <h1>Jonathan Hanke </h1>
          <p>Consulting Research Mathematician and Software Developer</p>
        </div>
*/}




  
        <div className="Images">


            <Grid>
              <Row>
                <Col xs={6} md={4}>

                  <div className="pic">
                  <Image src="sage1-cusp-dim-vs.-max-gal-deg.png" thumbnail />
                  </div>
                  <p />
                  <div className="pic">
                  <a href="Hanke--all_papers__as_of_2015-01-14/explicit-ternary-masses-by-det.pdf"> 
                  <Image src="Ternary Total Mass Formula.png" onMouseOver={blueBorder} onMouseOut={plainBorder} thumbnail />
                  </a>
                  </div>
                  <p />
                  <div className="pic">
                  <Image src="ln_cosh_with_t=one_quarter_doubled_through_four_gaussian.svg" thumbnail />
                  </div>
                  <p />
                  <div className="pic">
                  <Image src="Compact Resolvent Configuration.png" thumbnail />
                  </div>
                  <p />
                  <div className="pic">
                  <Image src="J1(m,b) optimal.png" thumbnail />
                  </div>
                  <p />
                  <div className="pic">
                  <Image src="J1(m,b) formula.png" thumbnail />
                  </div>

                </Col>
                <Col xs={6} md={4}>

                  <div className="pic">
{/*
                  <Image src="n-monogenic_class_group.png" thumbnail />
*/}
                  <a href="Hanke--2020_papers/2020-10_29__n-monogenized_paper.pdf"> 
                  <Image src="n-monogenic_class_group_smaller_upper_text.png" style={{padding:10}} class="linked_img" onMouseOver={blueBorder} onMouseOut={plainBorder} thumbnail />
                  </a>
                  </div>

                  <div className="lander2">
                    <h1>Jonathan Hanke </h1>
                    <p>Consulting Data Scientist, <br /> Research Mathematician, <br /> and Software Developer</p>
                    {/*
                    <p>Consulting Research Mathematician, <br /> Data Scientist, and Software Developer</p>
                    */}
                  </div>

                  <div className="pic">
                  <Image src="one_third_two_thirds_buy_and_hold_plot__2014-04-23.png" thumbnail />
                  </div>
                  <p />
                  <div className="pic">
                  <a href="Hanke--all_papers__as_of_2015-01-14/explicit-bounds-paper.pdf"> 
                  <Image src="Kneser_form_hypercube_with_small_image.png" style={{padding:20}} class="linked_img" onMouseOver={blueBorder} onMouseOut={plainBorder} thumbnail />
                  </a>
                  </div>
                  <p />
                  <div className="pic">
                  <Image src="Optimal Lines.png" thumbnail />
                  </div>

                </Col>
                <Col xs={6} md={4}>

                  <div className="pic">
                  <Image src="Level_set_for_x_squared_plus_xy_plus_y_squared_equals_13.png" thumbnail /> 
                  </div>
                  <p />
{/*
                  <div className="pic">
                  <Image src="290-thrm-long.png" thumbnail />
                  </div>
                  <p />
*/}
                  <div className="pic">
                  <Image src="290-thrm-vertical.png" thumbnail />
                  </div>
                  <p />
                  <div className="pic">
                  <Image src="sage2-max-gal-deg-vs.-cusp-const.png" thumbnail />
                  </div>
                  <p />
                  <div className="pic">
                  <Image src="J2(m,b) optimal.png" thumbnail />
                  </div>
                  <p />
                  <div className="pic">
                  <Image src="J2(m,b) formula.png" thumbnail />
                  </div>

                </Col>
              </Row>
            </Grid>

{/*
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
*/}

        </div>
  
      </div>

    );
  }
}