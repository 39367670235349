import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./App.css";
import Routes from "./Routes";


import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faCheckSquare, faEnvelope, faInbox, faHome, faCode, faInfinity, 
        faBuilding, faGraduationCap, faGlobeAmericas, faChalkboardTeacher, faUserFriends, 
        faUserEdit, faBinoculars, faGlasses, faPencilAlt, faUser, faUserTie, faSnowboarding,
        faSave, faTerminal, faStreetView} from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

library.add(faCoffee, faCheckSquare, faEnvelope, faInbox, faHome, 
            faCode, faInfinity, faBuilding, faGraduationCap, faGlobeAmericas, 
            faChalkboardTeacher, faUserFriends, faUserEdit, faBinoculars, faGlasses, 
            faPencilAlt, faUser, faUserTie, faSnowboarding, faSave, faTerminal, faStreetView, 
            faGithub, faLinkedin);




class App extends Component {
  render() {
    return (
      <div className="App container">

        <Navbar fixedTop fluid collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">jonhanke.com</Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Nav>
              <LinkContainer to="/about">
                <NavItem>
                About
                </NavItem>
              </LinkContainer>
              <LinkContainer to="/research">
{/*
                <NavItem>Research <br /> Overview</NavItem>
*/}
                <NavItem>Research Overview</NavItem>
              </LinkContainer>
              <LinkContainer to="/papers">
{/*
                <NavItem>Research <br /> Papers</NavItem>
*/}
                <NavItem>Research Papers</NavItem>
              </LinkContainer>
              <LinkContainer to="/software">
                <NavItem>Software</NavItem>
              </LinkContainer>
              <LinkContainer to="/teaching">
                <NavItem>Teaching</NavItem>
              </LinkContainer>
              <LinkContainer to="/mentoring">
                <NavItem>Mentoring</NavItem>
              </LinkContainer>
              <LinkContainer to="/outreach">
                <NavItem>Outreach</NavItem>
              </LinkContainer>
          </Nav>
{/*
          <Navbar.Collapse>
            <Nav pullRight>
              <LinkContainer to="/signup">
                <NavItem>Signup</NavItem>
              </LinkContainer>
              <LinkContainer to="/login">
                <NavItem>Login</NavItem>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
*/}
          <Navbar.Collapse>
            <Nav pullRight>
              <LinkContainer to="/resume">
                <NavItem>Resume/CV</NavItem>
              </LinkContainer>
              <LinkContainer to="/contact">
                <NavItem>Contact</NavItem>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>

        </Navbar>

        <div className="nav-header-buffer" />



        <Routes />



        <div className="nav-footer-buffer" />

        <div className="nav-footer">
        <Navbar fixedBottom>
          <Navbar.Header>
            <Navbar.Brand>
    {/*
              A <a href='http://symplelearning.com'>SympleLearning&trade;</a> Production!
     */}
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Nav pullRight>        
            <NavItem>
    {/*
          Copyright &#169; 2019 Hanke Enterprises LLC.  All rights reserved. 
     */}
      Copyright &#169; 2019 <a href='http://www.jonhanke.com'>Jonathan Hanke</a>.  All rights reserved. 
      </NavItem>
          </Nav>        
          </Navbar>
        </div>



      </div>
    );
  }
}

export default App;