import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Image, Grid, Row, Col } from "react-bootstrap";
import "./Resume-CV.css";
/*
import { Document, Page } from 'react-pdf/dist/entry.webpack';
*/

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



export default class Resume extends Component {
/*
    state = {
        numPages: null,
        pageNumber: 1,
      }

    onDocumentLoadSuccess = ({ numPages }) => {
      this.setState({ numPages });
    }
*/

    render() {
/*
    const { pageNumber, numPages } = this.state;
*/
    return (
      <div className="Resume">


        {/* https://en.wikipedia.org/wiki/File:Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg */}
        {/*
        <Image src="2048px-Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg" fluid />
        */}

{/*
        <div className="lander">
          <h1>Scratch</h1>
          <p>A simple note taking app</p>
        </div>
*/}

  
        <div className="lander">

          <center>
          <h1>Jonathan Hanke </h1>
          <p>Consulting Research Mathematician and Software Developer</p>

          <h1> &nbsp; </h1>
          </center>


          <Grid>
            <Row>
              <Col md={6} mdPush={6}>
                <center>
                <FontAwesomeIcon icon="graduation-cap" size="3x" />
                <h2> <Link to="/Hanke CV -- 2019-05-17.pdf" target="_blank" download> Curriculum Vitae </Link>  </h2>
                <p> (Academic) </p>
                {/* Hanke CV -- 2019-05-17.pdf */}
                </center>
              </Col>
              <Col md={6} mdPull={6}>
                <center>
                <FontAwesomeIcon icon="building" size="3x" />
                <h2> <Link to="/Hanke Resume -- 2019-06-09.pdf" target="_blank" download> Resume </Link>  </h2>
                <p> (Professional) </p>
{/*
                <Document
                  file="../Hanke Resume -- 2019-06-09.pdf"
                  onLoadSuccess={this.onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} />
                </Document>                
*/}                
                {/* Hanke Resume -- 2019-06-09.pdf */}
                </center>
              </Col>
            </Row>
          </Grid>

        </div>
  
      </div>

    );
  }
}