import React, { Component } from "react";
import { Image, Grid, Row, Col } from "react-bootstrap";
import "./Home.css";

export default class Home extends Component {
  render() {
    return (
      <div className="Home">


        {/* https://en.wikipedia.org/wiki/File:Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg */}
        {/*
        <Image src="2048px-Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg" fluid />
        */}

{/*
        <div className="lander">
          <h1>Scratch</h1>
          <p>A simple note taking app</p>
        </div>
*/}


        <div className="About me">
          <center>
            <h1>About Me</h1>
          </center>

          <p>
          I am a mathematician interested in Number Theory and Financial Mathematics 
          from both theoretical and computational perspectives.  My research focus has 
          been the study of quadratic forms over the integers, and their symmetries.  
          I have taught mathematics at Rutgers, Princeton, Duke, and the University of Georgia, 
          and have held visiting research positions at several mathematics research institutes 
          (MSRI, Max Planck Institute in Bonn).  I am also regularly involved with the PROMYS 
          summer program for high school students based at Boston University, 
          and was a founding member of the Board of Trustees for the PROMYS Foundation, and 
          serve on the Board of the Association of Princeton Graduate Alumni.
          </p>
        </div>

  
        <div className="Interests">
          <div>

          <center>
            <h1>Experience / Roles</h1>
{/*
              <p>Job Experience I've had</p>
*/}
          </center>


            <Grid>
              <Row>
                <Col md={6} mdPush={6}>
                  <h2> Consultant / Project Manager </h2>
                  <p>
                    <ul>
                      <li> Excels at teamwork and bringing out the best in others </li>
                      <li> Encourages people to achieve their potential and exceed expectations </li>
                      <li> Builds positive win-win relationships to bring people together </li>
                      <li> Facilitates successful project completion through teamwork and common goals </li>
                    </ul>
                  </p>
                </Col>
                <Col md={6} mdPull={6}>
                  <h2> Data Scientist </h2>
                  <p>
                    <ul>
                      <li> Cutting-edge knowledge of Research Mathematics </li>
                      <li> Proficient with the Python/Jupyter/Pandas/Scikit-Learn/Matplotlib/Seaborn Stack </li>
                      <li> Data Scraping with BeautifulSoup </li>
                      <li> Experience with Spark and Cloud Services </li>
                      <li> Developed Scalable serverless cloud platforms </li>
                    </ul>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={6} mdPush={6}>
                  <h2> Tutor / Teacher </h2>
                  <p>
                    <ul>
                      <li> Over 30 years of experience facilitating discovery-based learning across a diverse variety 
                            of backgrounds </li>
                      <li> Encourages independent thinking and using visual tools to communicate complex ideas simply</li>
                      <li> Proven record of success achieving learning objectives </li>
                    </ul>
                  </p>
                </Col>
                <Col md={6} mdPull={6}>
                  <h2> Full-Stack Software Developer </h2>
                  <p>
                    <ul>
                      <li> Proficient in Python / Cython / C++ / HTML / JavaScript / D3 / React </li>
                      <li> Experience with Cloud and Serverless development on AWS </li>
                      <li> Worked with many database technologes -- SQL, MongoDB, Spark, and flat CSV tables </li>
                      <li> Writes clean well-documented code with version control (Git/SVN) </li>
                      <li> Designed and Contributed Quadratic Forms functionality to the SAGE 
                            computer algebra system (Python, 20K+ lines)</li>
                      <li> Maintains ClassicalCryptography.com webpage for real-time exploration of 
                            classical ciphers and the ideas of Cryptography</li>
                    </ul>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={6} mdPush={6}>
                  <h2> Mentor / Advisor </h2>
                  <p>
                    <ul>
                      <li> Career coach for navigating career transitions, especially from academic 
                            to corporate environments </li>
                      <li> Research advisor for advanced high-school, undergraduate, and graduate 
                              (masters/Ph.D.) student research</li>
                      <li> Technical Mentor at Princeton Entrepreneurship Center </li>
                      <li> Research Mentor for the PROMYS program </li>
                      <li> Supervised dozens  of final student projects in mathematics and data science at 
                              top-ranked universities and data science programs </li>
                    </ul>
                  </p>
                </Col>
                <Col md={6} mdPull={6}>
                  <h2> Quantitative Researcher </h2>
                  <p>
                    <ul>
                      <li> Authored many original research papers on the 
                            Arithmetic of Quadratic Forms and Modular/Automorphic Forms </li>
                      <li> Designed algorithms and wrote Python/C++ open-source code to answer 
                            open research questions in Number Theory, Quadratic Forms, and Automorphic Forms </li>
                      <li> Worked on Stochastic Portfolio Theory adn co-authored the Wikipedia page on SPT 
                            with leading researchers in the area </li>
                    </ul>
                  </p>
                </Col>
              </Row>
            </Grid>


{/*
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
*/}
          </div>

        </div>
  
      </div>

    );
  }
}