import React, { Component } from "react";
import { Image, Grid, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import "./Papers.css";
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const pStyle = {
  marginLeft: '20px',
  marginRight: '20px',
};



export default class Home extends Component {
  render() {
    return (
      <div className="Generic">


        {/* https://en.wikipedia.org/wiki/File:Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg */}
        {/*
        <Image src="2048px-Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg" fluid />
        */}

{/*
        <div className="lander">
          <h1>Scratch</h1>
          <p>A simple note taking app</p>
        </div>
*/}



        <div className="Outreach">
          <div>

          <center>
            {/* globe-americas */}
            <h1>
            <FontAwesomeIcon icon="globe-americas" size="2x" />
            &nbsp; 
            Outreach 
            </h1>
{/*
              <p>Job Experience I've had</p>
*/}
          </center>





<h3> My Mathematics Videos </h3>
<ul>
<li>
<a href="Talks/BiCoMathColloquiumJonathanHanke091514.pdf">Quadratic Forms in Number Theory</a> — <a href="https://www.brynmawr.edu/math/colloquium">Brynn Mawr Bi-Co Mathematics Colloquium</a> (Sept 2014)
<br /> &nbsp;  &nbsp;  &nbsp;
(<a href="https://vimeo.com/106454429">Video</a>, <a href="Hanke-Quadratic-Forms-in-Number-Theory-Bi-Co-Colloquium-2014-09-15-Talk">Transparencies</a>)
</li>
<li>
<a href="Talks/To-Infinity-and-Beyond-talk-abstract-2014-09-13.pdf">To Infinity and Beyond</a> — Brookhaven National Lab Talk for advanced high school students (Sept 2014) 
<br /> &nbsp;  &nbsp;  &nbsp;
(Videos: <a href="https://vimeo.com/106432370">first half</a>, <a href="https://vimeo.com/106445345">second half</a>)
</li>
<li>
Does <InlineMath>{"\\mathbb{Z}\\left[\\frac{1 + \\sqrt{-23}}{2}\\right]"}</InlineMath> have 
unique prime factorization?  — PROMYS counselor mini-mini-course marathon (August 2014)
<br /> &nbsp;  &nbsp;  &nbsp;
 (<a href="http://vimeo.com/103405810">Video</a>, <a href="PROMYS_mini-mini-course-writeup-on_Q-23.pdf">Writeup</a>)
</li>
<li>
The 290-Theorem and Representing Numbers by Quadratic Forms — <a href="http://sites.math.rutgers.edu/~my237/expmath/">Rutgers Exp. Math Seminar</a> (Nov 2013) 
<br /> &nbsp;  &nbsp;  &nbsp;
(<a href="https://vimeo.com/80152913">Video</a>, <a href="http://sites.math.rutgers.edu/~my237/expmath/archive13.html#Hanke_2013-11-14">Abstract</a>, <a href="Hanke-Rutgers-2013-11-14-Talk-Transparencies-on-290-Theorem-and-Quadratic-forms.pdf">Transparencies</a>)
</li>
<li>
The Geometry of Projective Space — <a href="https://math.uconn.edu/mathclub-past-talks/">UConn Math Club</a> (April 2012)
<br /> &nbsp;  &nbsp;  &nbsp;
(<a href="http://vimeo.com/jonhanke/the-geometry-of-projective-space">Video</a>)
</li>
<li>
The Mathematics of Juggling — <a href="https://uga.campuslabs.com/engage/organization/MathClub">UGA Math Club</a> (Feb 2012)
<br /> &nbsp;  &nbsp;  &nbsp;
(<a href="https://vimeo.com/48922616">Video</a>, <a href="https://youtu.be/rTHlNfJXdeI">YouTube</a>, Juggling paper)
</li>
<li>
Random Numbers and the Zeta Function — PROMYS Guest Lecture (July 2011)
<br /> &nbsp;  &nbsp;  &nbsp;
(<a href="https://vimeo.com/35444641">Video</a>, Notes)
</li>
<li>
<a href="2011_Hanke_Evans-talk_feb14.pdf">The Arithmetic of Quadratic Forms</a> — <a href="https://math.berkeley.edu/about/events/lectures/spring-2011-msri-evans-lectures">MSRI Evans Lecture</a> (Feb 2011)
<br /> &nbsp;  &nbsp;  &nbsp;
(<a href="http://www.msri.org/web/msri/online-videos/-/video/showVideo/12164">Video</a>)
</li>
<li>
Lectures at the Arizona Winter School on “Quadratic Forms and Automorphic Forms” (March 2009):
<ul>
<li>
Lecture 1: Quadratic Spaces and Quadratic Lattices — (<a href="http://vimeo.com/jonhanke/aws-2009-hanke-lecture-1-quadratic-forms">Video</a>)
</li>
<li>
Lecture 2: Theta Series and Siegel’s Formula — (<a href="http://vimeo.com/jonhanke/aws-2009-hanke-lecture-2-theta-functions">Video</a>)
</li>
<li>
Lecture 3: The Clifford Algebra — (<a href="http://vimeo.com/user8044586/aws-2009-hanke-lecture-3-quaternions-and-clifford-algebras">Video</a>)
</li>
<li>
Lecture 4: Automorphic Forms and Theta Liftings — (<a href="http://vimeo.com/user8044586/aws-2009-hanke-lecture-4-automorphic-forms-and-theta-liftings">Video</a>)
</li>
<li>
Extended Lecture Notes (<a href="https://arxiv.org/pdf/1105.5759.pdf">PDF</a>)
</li>
</ul>
</li>
<li>
Informal Lectures on Modular forms for Circle Method Seminar at UGA (Fall 2009)
<ul>
<li>
Lecture 1: Introduction to theta series — (<a href="http://vimeo.com/36591709jonhanke/informal-circle-method-seminar-lecture-1">Video</a>)
</li>
<li>
Lecture 2: Trivial bounds and asymptotic formulas — (<a href="http://vimeo.com/36591709jonhanke/informal-circle-method-seminar-lecture-2">Video</a>)
</li>
<li>
Lecture 3: Local densities and the main term — (<a href="http://vimeo.com/36591709jonhanke/informal-circle-method-seminar-lecture-3">Video</a>)
</li>
</ul>
</li>
<li>
<a href="http://www.msri.org/seminars/19014">Introduction to Quadratic Forms and Modular Forms</a> — MSRI Informal Lecture (August 2006)
<br /> &nbsp;  &nbsp;  &nbsp;
(<a href="http://videodl.msri.org/13071/13071-13071-QuickTime.mov">Video</a>)
</li>
</ul>


<h3> My (non-video) Mathematics Lectures </h3>
<ul>
Understanding the (total) mass of Quadratic forms of fixed determinant (<a href="Banff-Talk-2011-11-18-Hanke-Understanding-the-total-mass-of-quadratic-forms-of-fixed-determinant.pdf">PDF</a>) — 
<br /> &nbsp;  &nbsp;  &nbsp;
<a href="http://www.birs.ca/events/2011/5-day-workshops/11w5011">Banff Workshop on “Diophantine Methods, Lattices and Arithmetic Theory of Quadratic Forms“</a>, November 18, 2011.
</ul>



{/*


            <Grid>
              <Row>
                <Col md={6} mdPush={6}>


                  <h3> Ph.D. Theses </h3>
                  <ul> 
                    <li> 
                      Kate Thompson — Explicit Representation Results for Quadratic Forms 
                      over <InlineMath>{"\\mathbb{Q}"}</InlineMath> and <InlineMath>{"\\mathbb{Q}(\\sqrt{5})"}</InlineMath> by 
                      Analytic Methods.  
                      <br />
                      (UGA 2008–2014; with secondary advisor Danny Krashen; Thesis)
                    </li>
                  </ul>

                  <h3> Masters Theses </h3>
                  <ul> 
                    <li> 
                      Laura Nunley — Geometry of Numbers approach to small solutions 
                      to the Extended Legendre equation
                      <br />
                      (UGA 2009–2010; secondary advisor to Pete Clark; Thesis)
                    </li>
                  </ul>



                  <h3> Undergraduate Theses </h3>
                  <ul> 
                    <li>
                      Mayank Varia — Explicit calculation of the <InlineMath>L</InlineMath>-function for Kummer surfaces
                      (Duke ????; Thesis with high distinction)
                    </li> 
                    <li>
                      Brandon Levin — ??? Binary Quadratic Forms ???
                      (Duke ????; secondary advisor to Les Saper)
                    </li> 
                  </ul>




                </Col>
                <Col md={6} mdPull={6}>


                  <h3> Undergraduate Independent Study Courses </h3>
                  <ul> 
                    <li>
                      Kate Thompson — Quaternion Algebras and Quadratic Forms
                      (UGA; Fall 2009, Spring 2010, Fall 2010)
                    </li> 
                    <li>
                      Mandy Frese — Number Theory and Elliptic Curve Cryptography
                      (Duke; ????, ????)
                    </li> 
                    <li>
                      Ebony Harvey — Reduction Theories for totally definite Binary Quadratic Forms 
                      over <InlineMath>{"\\mathbb{Z}(\\sqrt{2})"}</InlineMath>
                      (Duke; Summer ????)
                    </li> 
                    <li>
                      Mayank Varia —
                      (Duke; ???? )
                    </li> 
                    <li>
                      Two Crypto people… (Duke; Summer ????) — Combinatorics
                      Nina & Matt Young VIGRE Reading seminar at Rutgers.
                    </li> 
                  </ul>



                  <h3> PROMYS Returning Student Projects </h3>
                  <ul> 
                    <li>
                      Portfolio Theory
                      (PROMYS 2014; Description)
                    </li> 
                    <li>
                      Explicit Reduction theories for binary forms over <InlineMath>{"\\mathbb{Q}(\sqrt{2})"}</InlineMath>
                      (PROMYS 2011; Description)
                    </li> 
                    <li>
                      Conway’s topographs for binary forms over <InlineMath>{"\\mathbb{Q}(\sqrt{2})"}</InlineMath>
                      (PROMYS 2011; Description)
                    </li> 
                    <li>
                      Finiteness Theorems for Binary Hermitian forms over <InlineMath>{"\\mathbb{Z}"}</InlineMath>
                      (PROMYS 2009; Description)
                    </li> 
                    <li>
                      Quaternion arithmetic
                      (PROMYS 2008)
                    </li> 
                  </ul>


                </Col>
              </Row>

            </Grid>

*/}

{/*
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
*/}
          </div>

        </div>
  
      </div>

    );
  }
}