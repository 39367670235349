import React, { Component } from "react";
import { Image, Grid, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import "./Papers.css";
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const pStyle = {
  marginLeft: '20px',
  marginRight: '20px',
};



export default class Home extends Component {
  render() {
    return (
      <div className="Generic">


        {/* https://en.wikipedia.org/wiki/File:Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg */}
        {/*
        <Image src="2048px-Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg" fluid />
        */}

{/*
        <div className="lander">
          <h1>Scratch</h1>
          <p>A simple note taking app</p>
        </div>
*/}



        <div className="Mentoring">
          <div>

          <center>
            {/* user-friends */}
            <h1>
            <FontAwesomeIcon icon="user-friends" size="2x" />
            &nbsp; 
            Mentoring 
            </h1>
{/*
              <p>Job Experience I've had</p>
*/}
          </center>

            <Grid>
              <Row>

                <Col md={6} mdPush={6}>


{/*
                  <h3> Undergraduate Independent Study Courses </h3>
                  <ul> 
                    <li>
                      Kate Thompson — 
                      Quaternion Algebras and Quadratic Forms
                      <br /> &nbsp;  &nbsp;  &nbsp;
                      (UGA; Fall 2009, Spring 2010, Fall 2010)
                    </li> 
                    <li>
                      Mandy Frese — 
                      Number Theory and Elliptic Curve Cryptography
                      <br /> &nbsp;  &nbsp;  &nbsp;
                      (Duke; ????, ????)
                    </li> 
                    <li>
                      <a href="https://www.linkedin.com/in/ebonyannharvey/">Ebony Harvey</a> — Reduction Theories for totally definite Binary Quadratic Forms 
                      over <InlineMath>{"\\mathbb{Z}(\\sqrt{2})"}</InlineMath>
                      <br /> &nbsp;  &nbsp;  &nbsp;
                      (Duke; Summer ????)
                    </li> 
                    <li>
                      Mayank Varia —  Topics in Number Theory 
                      <br /> &nbsp;  &nbsp;  &nbsp;
                      (Duke; ???? )
                    </li> 
                    <li>
                      Two Crypto people… (Duke; Summer ????) — Combinatorics
                      <br /> &nbsp;  &nbsp;  &nbsp;
                      Nina & Matt Young VIGRE Reading seminar at Rutgers.
                    </li> 
                  </ul>
*/}


{/*
                  <h3> Undergraduate Independent Study Courses </h3>
                  <ul> 
                    <li> 
                      UGA — Fall 2009, Spring 2010, Fall 2010
                    </li> 
                    <li> 
                      Duke —                     
                    </li> 
                    <li> 
                      Rutgers — 
                    </li> 
                  </ul>



                  <h3> Undergraduate Number Theory Course Final Papers </h3>
                  <ul> 
                    <li> 
                    Duke -- 10-15 students annually with final papers in the following topics
                    <ul> 
                      <li> 
                        Arithmetic of Cycltotmic Fields
                      <li> 
                      </li> 
                        Public-Key Cryptography
                      <li> 
                      </li> 
                        Unique Prime Factorization
                      <li> 
                      </li> 

                      </li> 
                    </ul>
                    </li> 
                  </ul>
*/}

                  <h3> <a href="http://promys.org">PROMYS</a> Returning Student Projects </h3>
                  <ul> 
                    <li>
                      <a href="Student_Project_Description_Writeups/PROMYS_2016_monogenic_class_numbers_research_project__Hanke_Varma_Yott__2016-06-27.pdf">Class Numbers of Monogenic Cubic Fields</a> — 
                      <br /> &nbsp;  &nbsp;  &nbsp;
                      with <a href="http://www.math.toronto.edu/~ila/">Ila Varma</a> and <a href="https://www.linkedin.com/in/dylan-yott-634456bb">Dylan Yott</a> — 2016
                    </li> 
                    <li>
                      Portfolio Theory — 2014
                    </li> 
                    <li>
                      Explicit Reduction theories for binary forms over <InlineMath>{"\\mathbb{Q}(\\sqrt{2})"}</InlineMath> — 2011
                    </li> 
                    <li>
                      Conway’s topographs for binary forms over <InlineMath>{"\\mathbb{Q}(\\sqrt{2})"}</InlineMath> — 2011
                    </li> 
                    <li>
                      Finiteness Theorems for Binary Hermitian forms over <InlineMath>{"\\mathbb{Z}"}</InlineMath> — 2009
                    </li> 
                    <li>
                      Quaternion arithmetic — 2008
                    </li> 
                  </ul>


                </Col>


                <Col md={6} mdPull={6}>


                  <h3> Ph.D. Theses </h3>
                  <ul> 
                    <li> 
                      <a href="https://sites.google.com/site/katethompsonhomepage/">Kate Thompson</a> — Explicit Representation Results for Quadratic Forms 
                      over <InlineMath>{"\\mathbb{Q}"}</InlineMath> and <InlineMath>{"\\mathbb{Q}(\\sqrt{5})"}</InlineMath> by 
                      Analytic Methods.  
                      <br /> &nbsp;  &nbsp;  &nbsp;
                      (UGA 2008–2014; primary advisor with secondary advisor <a href="http://dkrashen.github.io/">Danny Krashen</a>)
                    </li>
                  </ul>


                  <h3> Masters Theses </h3>
                  <ul> 
                    <li> 
                      Laura Nunley — Geometry of Numbers approach to small solutions 
                      to the Extended Legendre equation
                      <br /> &nbsp;  &nbsp;  &nbsp;
                      (UGA 2009–2010; secondary advisor with primary advisor <a href="http://math.uga.edu/~pete/">Pete Clark</a>)
                    </li>
                  </ul>



                  <h3> Undergraduate Theses </h3>
                  <ul> 
                    <li>
                      <a href="https://www.mvaria.com/">Mayank Varia</a> — Explicit calculation of the <InlineMath>L</InlineMath>-function for Kummer surfaces
                      <br /> &nbsp;  &nbsp;  &nbsp;
                      (Duke 2004—2005; Thesis with high distinction)
                    </li> 
                    <li>
                      <a href="https://www.math.arizona.edu/~bwlevin/">Brandon Levin</a> —  Binary Quadratic Forms
                      <br /> &nbsp;  &nbsp;  &nbsp;
                      (Duke 2007; secondary advisor with primary advisor <a href="http://fds.duke.edu/db/aas/math/faculty/saper/">Leslie Saper</a>)
                    </li> 
                  </ul>



                </Col>

              </Row>

            </Grid>

{/*
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
*/}
          </div>

        </div>
  
      </div>

    );
  }
}
