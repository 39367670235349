import React, { Component } from "react";
import { Image, Grid, Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./Contact.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';



export default class Home extends Component {
  render() {
    return (
      <div className="Home">


        {/* https://en.wikipedia.org/wiki/File:Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg */}
        {/*
        <Image src="2048px-Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg" fluid />
        */}

{/*
        <div className="lander">
          <h1>Scratch</h1>
          <p>A simple note taking app</p>
        </div>
*/}


{/*
        <div className="About me">
          <center>
            <h1>About Me</h1>
          </center>

          <p>
          I am a mathematician interested in Number Theory and Financial Mathematics 
          from both theoretical and computational perspectives.  My research focus has 
          been the study of quadratic forms over the integers, and their symmetries.  
          I have taught mathematics at Rutgers, Princeton, Duke, and the University of Georgia, 
          and have held visiting research positions at several mathematics research institutes 
          (MSRI, Max Planck Institute in Bonn).  I am also regularly involved with the PROMYS 
          summer program for high school students based at Boston University, 
          and was a founding member of the Board of Trustees for the PROMYS Foundation, and 
          serve on the Board of the Association of Princeton Graduate Alumni.
          </p>
        </div>
*/}



        <div className="Contact">
{/*
          <p>Webpage of</p>
*/}
          <h1>Jonathan Hanke </h1>
          <p>Consulting Data Scientist, Research Mathematician, and Software Developer</p>
          {/*
          <p>Consulting Research Mathematician, Data Scientist, and Software Developer</p>
          */}


          <h1> &nbsp; </h1>


            <Grid>
              <Row>
                <Col xs={6} md={4}>
                  <a href="https://www.linkedin.com/in/jonathanhanke">
                    <h4> LinkedIn </h4>
                    <FontAwesomeIcon icon={ faLinkedin } size="3x" />
                  </a>
                </Col>
                <Col xs={6} md={4}>
                  <a href='http://github.jonhanke.com'>  
                    <h4> Github </h4>
                    <FontAwesomeIcon icon={ faGithub } size="3x" />
                  </a>
                </Col>
                <Col xs={6} md={4}>
                  <a href='http://arxiv.jonhanke.com'>  
                    <h4> Arxiv </h4>
                    <FontAwesomeIcon icon="infinity" size="3x" />
                  </a>  
                </Col>
              </Row>

              <h1> &nbsp; </h1>
              <h1> &nbsp; </h1>

              <Row>   
                <Col xs={6} md={2} />
                <Col xs={6} md={4}>
                  <OverlayTrigger
                    key= 'email'
                    placement='bottom'
                    overlay={
                      <Tooltip id={`tooltip-email`}>
                        jonhanke ( at ) gmail.com
                      </Tooltip>
                    }
                  >
                    <Button class="clear-button" variant="link">
                      <h4> E-mail </h4>
                      <FontAwesomeIcon icon="inbox" size="3x" />
                    </Button>
                  </OverlayTrigger>
                </Col>
                <Col xs={6} md={4}>
                  <OverlayTrigger
                    key= 'address'
                    placement='bottom'
                    overlay={
                      <Tooltip id={`tooltip-address`}>
                        Jonathan Hanke <br />
                        124 John Street <br />
                        Princeton, NJ 08542 USA
                      </Tooltip>
                    }
                  >
                    <Button class="clear-button" variant="link">
                      <h4> Mailing Address </h4>
                      <FontAwesomeIcon icon="home" size="3x" />
                    </Button>
                  </OverlayTrigger>                    
                </Col>
                <Col xs={6} md={2} />
              </Row>
            </Grid>

        </div>



  
        <div className="Interests">
          <div>




{/*
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
*/}
          </div>

        </div>
  
      </div>

    );
  }
}