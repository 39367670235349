import React, { Component } from "react";
import { Image, Grid, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import "./Papers.css";
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const pStyle = {
  marginLeft: '20px',
  marginRight: '20px',
};



export default class Home extends Component {
  render() {
    return (
      <div className="Generic">


        {/* https://en.wikipedia.org/wiki/File:Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg */}
        {/*
        <Image src="2048px-Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg" fluid />
        */}

{/*
        <div className="lander">
          <h1>Scratch</h1>
          <p>A simple note taking app</p>
        </div>
*/}



        <div className="Teaching">
          <div>

          <center>
            <h1>
            {/* chalkboard-teacher */}
            <FontAwesomeIcon icon="chalkboard-teacher" size="2x" />
            &nbsp; 
            Teaching 
            </h1>
{/*
              <p>Job Experience I've had</p>
*/}
          </center>

            <Grid>
              <Row>
                <Col md={6} mdPush={6}>

                  <h3> Duke University (2003 — 2007) </h3>
                  <ul>
                    <li>
                      Multivariable Calculus — (<a href="https://services.math.duke.edu/psa/local/newnum">Math 103</a>, <a href="https://services.math.duke.edu/psa/local/newnum">103X</a>) — Fall 2003, Fall 2005
                    </li>
                    <li>
                      Number Theory* — (<a href="https://services.math.duke.edu/psa/local/newnum">Math 128S</a>) — Spring 2004, Spring 2005, Spring 2006, Spring 2007
                    </li>
                    <li>
                      Linear Algebra — (<a href="https://services.math.duke.edu/psa/local/newnum">Math 104</a>) — Fall 2004
                    </li>
                    <li>
                      Cryptography — (<a href="https://services.math.duke.edu/psa/local/newnum">Math 49S</a>) — Fall 2005
                    </li>
                    <li>
                      Combinatorics — (<a href="https://services.math.duke.edu/psa/local/newnum">Math 124S</a>) — Fall 2004, [Summer 2006*]
                    </li>
                  </ul>


                  <h3> Princeton University (2002 — 2003) </h3>
                  <ul>
                    <li>
                      Linear Algebra — (<a href="https://www.math.princeton.edu/undergraduate/placement/MAT202">Math 202</a> Course coordinator) — Spring 2003
                    </li>
                    <li>
                      Calculus I — (<a href="https://www.math.princeton.edu/undergraduate/placement/MAT103">Math 103</a> Course coordinator) —  Fall 2003
                    </li>
                  </ul>


                  <h3> Rutgers University (1999 — 2002) </h3>
                  <ul>
                    <li>
                      Linear Algebra — (<a href="https://math.rutgers.edu/academics/undergraduate/courses/948-01-640-250-introductory-linear-algebra">Math 250</a>) — Spring 2002
                    </li>
                    <li>
                      Cryptography — (<a href="https://math.rutgers.edu/academics/undergraduate/courses/930-01-640-103-h-topics-in-mathematics-for-the-liberal-arts-honors-section">Math 103H</a>) —  Fall 2000, Spring 2000, Fall 2001
                    </li>
                    <li>
                      Calculus I — (<a href="https://math.rutgers.edu/academics/undergraduate/courses/938-01-640-135-calculus-i">Math 135</a>) — Spring 1999, Fall 1999
                    </li>
                  </ul>



                  <h3> Princeton University (1995 — 1999, GS) </h3>
                  <ul>
                    <li>
                      Calculus I — (<a href="https://www.math.princeton.edu/undergraduate/placement/MAT103">Math 103</a>) — Fall 1998
                    </li>
                  </ul>





                </Col>
                <Col md={6} mdPull={6}>

                  <h3> Princeton University (2015 — Present)</h3>
                  <ul>
                    <li>  
                     Research Projects in Data Science — (<a href="https://csml.princeton.edu/undergraduates/sml-310-researchprojects">SML310</a>) — Fall 2020
                    <br />
                    </li>
                    <li>  
                      Secrecy, Search, and Society — (<a href="https://odoc.princeton.edu/curriculum/freshman-seminars/seminars-fall-term-2019#compfrs141">FRS141, FRS 170</a>) — 2015 - 2019; Annually
                    <br />
                    </li>
                  </ul>


                  <h3> University of Georgia (2008 — 2011)</h3>
                  <ul>
                    <li>
                      Business Calculus I — (<a href = "http://www.bulletin.uga.edu/CoursesHome.aspx?cid=861">Math 2200</a>) — Fall 2011, Fall 2010
                    </li>
                    <li>
                      Math Teaching Seminar* — (<a href="http://www.bulletin.uga.edu/CoursesHome.aspx?cid=15584">Math 9005</a>) — Summer 2011
                    </li>
                    <li>
                      Calculus I — (<a href="http://www.bulletin.uga.edu/CoursesHome.aspx?cid=17372">Math 2250</a>) — Fall 2008
                    </li>
                    <li>
                      Calculus II — (<a href="http://www.bulletin.uga.edu/CoursesHome.aspx?cid=17373">Math 2260</a>) — Fall 2009
                    </li>
                    <li>
                      Cryptography* — (<a href="http://www.bulletin.uga.edu/CoursesHome.aspx?cid=4812">Math 4450/6450</a>) — Spring 2010
                    </li>
                    <li>
                      Modular Forms* — (<a href="http://www.bulletin.uga.edu/CoursesHome.aspx?cid=1518">Math 8440</a>) — Spring 2009
                    </li>
                  </ul>


                <h3> Visiting Courses (2009) </h3>
                <ul>
                  <li>
                    Quadratic Forms and Automorphic Forms — <a href="http://swc.math.arizona.edu/aws/2009/index.html">Arizona Winter School</a> — March 2009 {/* (for graduate students) */}
                            [Lecture Videos: <a href="http://vimeo.com/jonhanke/aws-2009-hanke-lecture-1-quadratic-forms">1</a>, <a href="http://vimeo.com/jonhanke/aws-2009-hanke-lecture-2-theta-functions">2</a>, <a href="http://vimeo.com/user8044586/aws-2009-hanke-lecture-3-quaternions-and-clifford-algebras">3</a>, <a href="http://vimeo.com/user8044586/aws-2009-hanke-lecture-4-automorphic-forms-and-theta-liftings">4</a>; Lecture Notes: <a href="https://arxiv.org/pdf/1105.5759.pdf">PDF</a>] </li>
                  <li>
                    <a href="http://qseries.org/fgarvan/quadformsconf/workshop-program/hanke-lectures.html">Quadratic Forms</a> — <a href="http://qseries.org/fgarvan/quadformsconf.html">Quadratic Forms, Sums of Squares, Theta functions and Integral Lattices Workshop</a> — March 2009 
                      {/* (for undergraduates) */} </li>
                  <li>
                    Modular Forms — <a href="http://www.promys.org/">PROMYS</a> Returning Course — Summer 2009 {/* (for high school students/teachers)  */}
                  </li>
                </ul>


                  <h3> Max Plank Institute, Bonn (2007 — 2008)</h3>
                  <ul>
                    <li>  
                      Informal Lectures about explicit finiteness theorems and 
                      representing numbers by a quadratic form 
                    <br />                      
                    </li>
                  </ul>


                </Col>
              </Row>

            </Grid>

{/*
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
*/}
          </div>

        </div>
  
      </div>

    );
  }
}