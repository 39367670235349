import React, { Component } from "react";

class Login extends Component {
	render() {
	    return (
	      <div className='Login-component'/>
	    );
	}
}

export default Login;