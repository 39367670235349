import React, { Component } from "react";
import { Image, Grid, Row, Col } from "react-bootstrap";
import "./Home.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default class Home extends Component {
  render() {
    return (
      <div className="Home">


        {/* https://en.wikipedia.org/wiki/File:Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg */}
        {/*
        <Image src="2048px-Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg" fluid />
        */}

{/*
        <div className="lander">
          <h1>Scratch</h1>
          <p>A simple note taking app</p>
        </div>
*/}


        <div className="About me">
          <center>
            <h1>
            {/* user snowboarding*/}
            <FontAwesomeIcon icon="snowboarding" size="2x" />
            &nbsp; 
            About Me</h1>
          </center>

          <p>
          I am a mathematician interested in Data Science, Number Theory, and Financial Mathematics 
          from both theoretical and computational perspectives.  My research focus has 
          been the study of quadratic forms over the integers, theta functions, automorphic forms, 
          and stochastic/probabilistic portfolio theory.  
          I have taught mathematics 
          at <a href="https://www.rutgers.edu/">Rutgers</a>, <a href="https://www.princeton.edu/">Princeton</a>, <a href="https://www.duke.edu/">Duke</a>, 
          and the <a href="https://www.uga.edu/">University of Georgia</a>, 
          and have held visiting research positions at several mathematics research institutes 
          (<a href="https://www.msri.org/">MSRI</a>, <a href="https://www.mpim-bonn.mpg.de/">Max Planck Institute</a> in Bonn).  
          I am also regularly involved with 
          the <a href="https://promys.org/">PROMYS summer program </a> 
          for high school students based at Boston University, 
          was a founding member of the Board of Trustees for 
          the <a herf="https://www.promysfoundation.org/">PROMYS Foundation</a>, and 
          serve on the Board of 
          the <a href="http://apga.tigernet.princeton.edu">Association of Princeton Graduate Alumni</a>.
          </p>
        </div>

  
        <div className="Interests">
          <div>
            <h4>
            &nbsp;
            </h4>

          <center>
            <h1>
            {/* user-tie */}
            <FontAwesomeIcon icon="user-tie" size="2x" />
            &nbsp; 

            Experience / Roles</h1>
{/*
              <p>Job Experience I've had</p>
*/}
          </center>


            <Grid>
              <Row>
                <Col md={6} mdPush={6}>
                  <h2> Consultant / Project Manager </h2>
                  <p>
                    <ul>
                      <li> <a href="https://www.globalcreditdata.org/news/we-welcome-our-new-member-goldman-sachs">Proven record of success</a> building and managing internal full-time and part-time teams, 
                              as well as external consultant engagements to meet evolving project deadines ahead of schedule </li>
                      <li> Facilitates successful project completion through effective communication with stakeholders and team members,
                             setting responsibilities for deliverables, and achieving common goals </li>                             
                      <li> Builds positive win-win relationships to bring diverse sets of stakeholders together to achieve project objectives</li>
                      <li> Encourages people to achieve their potential and exceed expectations </li>
                      <li> Excels at teamwork and bringing out the best in others </li>
                    </ul>
                  </p>

                  <h2> Tutor / Teacher / Presenter</h2>
                  <p>
                    <ul>
                      <li> Proven record of success achieving learning objectives, earning top <a href="https://www.questionpro.com/blog/nps-considered-good-net-promoter-score/amp/">Net Promoter Scores</a> (NPS) on par with world-class brands </li>
                      <li> Extensive experience facilitating discovery-based learning across a diverse variety 
                            of backgrounds and environments</li>
                      <li> Encourages independent thinking and using visual tools to communicate complex ideas simply </li>
                      <li> Teaches annual seminar on <a href="https://odoc.princeton.edu/curriculum/freshman-seminars/seminars-fall-term-2019#compfrs141">"Secrecy, Search, and Society"</a> covering classical and modern cryptography, PageRank, and Bitcoin, as a foundation for discussions about algorithms, technology, and incentives shaping our modern digital world. </li>
                    </ul>
                  </p>

                  <h2> Mentor / Advisor </h2>
                  <p>
                    <ul>
                      <li> Career coach for navigating career transitions, especially from academic 
                            to corporate environments </li>
                      <li> Research advisor for advanced high-school, undergraduate, and graduate 
                              (Masters / Ph.D.) student research</li>
                      <li> <a href="http://128.112.38.56/create/entrepreneurial-hub/office-hours/john-hanke/">Project Mentor</a> at <a href="https://kellercenter.princeton.edu/">Princeton Entrepreneurship Center</a> </li>
                      <li> Counselor, Research Mentor, and Teacher for the <a href="http://www.promys.org">PROMYS</a> program</li>
                      <li> Supervised dozens  of final student projects in mathematics and data science at 
                              top-ranked universities and data science programs </li>
{/*
                      <li> Panel speaker at Princeton Careers in Data Science events</li>
*/}
                    </ul>
                  </p>

                </Col>
                <Col md={6} mdPull={6}>
                  <h2> Data Scientist </h2>
                  <p>
                    <ul>
                      <li> Cutting-edge knowledge of Research Mathematics and Algorithm Development</li>
                      <li> Proficient with the <a href="https://www.python.org/">Python</a> / <a href="https://jupyter.org/">Jupyter</a> / <a href="https://pandas.pydata.org">Pandas</a> / <a href="https://scikit-learn.org/stable/">Scikit-Learn</a> / <a href="https://matplotlib.org/">Matplotlib</a> / <a href="https://seaborn.pydata.org/">Seaborn</a> stack </li>
                      <li> Trained and Advised 50-60 Data Scientists in an <a href="https://www.thisismetis.com/data-science-bootcamps">immersive full-stack project-based environment</a> </li>
                      <li> Real-world experience with data science and automation in financial services and regulatory reporting </li>
{/*
                      <li> Data Scraping with BeautifulSoup </li>
                      <li> Experience with Spark and Cloud Services </li>
                      <li> Developed Scalable serverless cloud platforms </li>
*/}
                    </ul>
                  </p>
 
                  <h2> Full-Stack Software Developer </h2>
                  <p>
                    <ul>
                      <li> Proficient in <a href="https://www.python.org/">Python</a> / <a href="https://cython.org/">Cython</a> / <a href="http://www.cplusplus.com/">C++</a> / <a href="https://www.w3.org/html/">HTML</a> / <a href="https://en.wikipedia.org/wiki/JavaScript">JavaScript</a> / <a href="https://d3js.org/">D3</a> / <a href="https://reactjs.org/">React</a> </li>
                      <li> Experience with Cloud and Serverless development on <a href="https://aws.amazon.com/">AWS</a> </li>
                      <li> Worked with many database technologes -- <a href="https://en.wikipedia.org/wiki/SQL">SQL</a>, <a href="https://www.mongodb.com/">MongoDB</a>, <a href="https://spark.apache.org/">Spark</a>, <a href="https://products.office.com/en-us/excel">Excel</a>, and flat <a href="https://en.wikipedia.org/wiki/Comma-separated_values">CSV</a> tables </li>
                      <li> Writes clean well-documented code with version control (<a href="https://git-scm.com/">Git</a> / <a href="https://subversion.apache.org/">SVN</a>) </li>
                      <li> Designed/Implemented Quadratic Forms functionality for the <a href="http://www.sagemath.org/">SAGE</a> open-source
                            computer algebra system (Python, 20K+ lines)</li>
                      <li> Maintains <a href="http://classicalcryptography.com">ClassicalCryptography.com</a> webpage for real-time exploration of 
                            classical ciphers and the ideas of Cryptography</li>
                    </ul>
                  </p>

                  <h2> Quantitative Researcher </h2>
                  <p>
                    <ul>
                      <li> Authored many original research papers on the 
                            Arithmetic of Quadratic Forms and Modular/Automorphic Forms </li>
                      <li> Designed algorithms and wrote Python/C++ open-source code to answer 
                            open research questions in Number Theory, Quadratic Forms, and Automorphic Forms </li>
                      <li> Worked on <a href="https://www.math.columbia.edu/~ik/FernKarSPT.pdf">Stochastic Portfolio Theory</a> and co-authored 
                            the <a href="https://en.wikipedia.org/wiki/Stochastic_portfolio_theory">Wikipedia page</a>  with leading researchers in the area </li>
                      <li> Invited speaker at <a href="http://princetonquanttrading.org/conferences/2019-spring/">Princeton Fintech and Quant Conference</a> </li>
                      <li> Work on the <a href="https://math.nd.edu/assets/20630/hahntoulouse.pdf">290-Theorem</a> mentioned on <a href="https://www.mathunion.org/fileadmin/IMU/Prizes/Fields/2014/news_release_bhargava.pdf">2014 Fields Medal Citation</a> of Manjul Bhargava, and subsequently by <a href="https://www.youtube.com/watch?v=JybQ-6OTrLQ&feature=youtu.be&t=1h6m17s">John Conway</a>.</li>                      
                    </ul>
                  </p>

                </Col>
              </Row>
            </Grid>


{/*
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
*/}
          </div>

        </div>
  
      </div>

    );
  }
}