import React, { Component } from "react";
import { Image, Grid, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import "./Papers.css";
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const pStyle = {
  marginLeft: '20px',
  marginRight: '20px',
};



export default class Home extends Component {
  render() {
    return (
      <div className="Generic">


        {/* https://en.wikipedia.org/wiki/File:Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg */}
        {/*
        <Image src="2048px-Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg" fluid />
        */}

{/*
        <div className="lander">
          <h1>Scratch</h1>
          <p>A simple note taking app</p>
        </div>
*/}



        <div className="Software">
          <div>

          <center>
            <h1>
            {/* save terminal  */}
            <FontAwesomeIcon icon="save" size="2x" />
            &nbsp; 
            Software</h1>
{/*
              <p>Job Experience I've had</p>
*/}
          </center>

            <Grid>
              <Row>
                <Col md={6} mdPush={6}>


                  <h3> Automorphic Forms Software</h3>
                  <ul>
                    <li>  
                    <a href="https://github.com/jonhanke/eclib-with-quadchar">ECLIB modifications to allow a quadratic character</a>
                    <br />
                    with John Cremona, C, selective changes.
                    </li>
                    <li>  
                    <a href="https://github.com/jonhanke/theta-eigenform-decomposition--magma">Theta function MAGMA cuspidal decomposition routines</a>
                    <br />
                    <a href="http://magma.maths.usyd.edu.au/magma/">MAGMA</a>, ~1,500 lines.
                    </li>
                    <li>  
                    <a href="https://github.com/jonhanke/Quaternion_Brandt_Matrices_in_SAGE">Computing Brandt matrices</a>
                    <br />
                    with Lassina Dembele, Python, ~1,000 lines.
                    </li>
                    <li>
                    <a href="https://github.com/jonhanke/Formal_Dirichlet_Series_for_SAGE">Formal Dirichlet series code</a>
                    <br />
                    Python, ~450 lines.
                    </li>
                  </ul>


                  <h3> Cryptography / Probability </h3>
                  <ul>
                    <li>
                      <a href="http://ClassicalCryptography.com">ClassicalCryptography.com</a> 
                    <br />
                      JSX/React, ~1,000 lines.
                    <br />
                      Python, ~1,100 lines.
                    </li>
{/*
<li>
<a href="https://github.com/jonhanke/classical-crypto-routines">Previous ClassicalCryptography.com website</a>
https://github.com/jonhanke/classical-crypto-routines
                    <br />
Flask/Python, ~1,100 lines.
                    <br />
HTML/JS, ~1,500 lines.
</li>
*/}
                    <li>
                      <a href="https://github.com/jonhanke/ZZ_mod_nZZ_probability_distributions">Probability distributions on <InlineMath>{"\\mathbb{Z}/n\\mathbb{Z}"}</InlineMath></a>
                    <br />
                      Python, 627 lines.
                    </li>
                    <li>
                      <a href="https://github.com/jonhanke/simple_letter_statistics">Computing <InlineMath>n</InlineMath>-gram letter statistics</a>
                    <br />
                      Python, ~400 lines.
                    </li>
{/*
                    <li>
                      Exact convolution of piecewise polynomial function
                    <br />
                      Python, ~??? lines.
                    </li>
*/}
                  </ul>

                </Col>
                <Col md={6} mdPull={6}>


                  <h3> Quadratic Forms Software</h3>
                  <ul>
                    <li>  
                    <a href="https://github.com/jonhanke/QFLib">QFLIB</a> - Quadratic Forms library for integer-valued quadratic forms 
                    <br />
                    C++, ~20,000 lines.
                    </li>
                    <li>
                    <a href="https://github.com/jonhanke/Quadratic_Forms_for_SAGE">Quadratic forms code for SAGE</a>
                    <br />
                    Python, ~22,000 lines.
                    </li>
                    <li>
                    <a href="https://github.com/jonhanke/Quadratic_Spaces_and_Quadratic_Lattices_for_SAGE">Quadratic space and quadratic lattice code for SAGE</a>
                    <br />
                    Python ~11,000 lines.
                    </li>
{/*
                    <li>
                    PARI/GP code to compute local densities
                    <br />
                    GP, ~??? lines.
                    </li>
*/}
                    <li>
                    <a href="https://github.com/jonhanke/Binary_Quadratic_Forms_graphics_patch_for_SAGE">Graphics for binary quadratic forms in Sage/Python</a>
                    <br />
                    Sage, 300 lines.
                    </li>
                    <li>
                    <a href="https://github.com/jonhanke/Total_masses_of_quadratic_forms">Computing total masses of binary and ternary quadratic forms</a>
                    <br />
                    Python, 1,500 lines.
                    </li>
                  </ul>



                  <h3> Voronoi Tesselation </h3>
                  <ul>
                    <li>  
                      <a href="https://github.com/jonhanke/Voronoi_tesselation_PDF_generator">Voronoi tessellation PDF generator</a>
                    <br />
                      with Dan Yasaki, PostScript, 147 lines.
                    </li>
                  </ul>


                </Col>
              </Row>
            </Grid>

{/*
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
                <li>  </li>
*/}
          </div>

        </div>
  
      </div>

    );
  }
}