import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "./containers/Home";
import About from "./containers/About";
import Experience from "./containers/Experience";
import Research from "./containers/ResearchOverview";
import Papers from "./containers/Papers";
import Software from "./containers/Software";
import Teaching from "./containers/Teaching";
import Mentoring from "./containers/Mentoring";
import Outreach from "./containers/Outreach";

import Resume from "./containers/Resume-CV";
import Contact from "./containers/Contact";



export default () =>
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/about" exact component={About} />
    <Route path="/papers" exact component={Papers} />
    <Route path="/research" exact component={Research} />
    <Route path="/software" exact component={Software} />
    <Route path="/teaching" exact component={Teaching} />
    <Route path="/mentoring" exact component={Mentoring} />
    <Route path="/outreach" exact component={Outreach} />

    <Route path="/resume" exact component={Resume} />
    <Route path="/contact" exact component={Contact} />
</Switch>;
